import React from 'react'
import Navbar from '../components/Navbar'
import Headings from '../components/Headings'

const Windows = () => {
  return (
    <div>
        <Navbar/>
        <Headings headingName="Windows" />
    </div>
  )
}

export default Windows;