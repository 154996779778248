import React from 'react';
import styled from 'styled-components';

const PlumbingDesc = () => {
  return (
    <SectionContainer>
       <ImageColumn>
        <Image src="https://firebasestorage.googleapis.com/v0/b/champion-building.appspot.com/o/BD25BA26-B77F-419A-B5B8-7DF9AE152CDD.jpeg?alt=media&token=e364b784-6c7f-4807-b66a-5576a3b110f8" alt="plumbing work" />
      </ImageColumn>
      <DescriptionColumn>

      <Description>
  <h2>Expert Plumbing Solutions for Every Project</h2>
  <p>
    Trust Champion & Champion for high-quality plumbing solutions tailored to your needs. Whether it's a residential renovation, commercial construction, or industrial project, our team ensures that every plumbing aspect is handled with care and expertise.
  </p>
  <p>
    With a dedication to integrity, innovation, and excellence, we guarantee that every plumbing project receives the attention it deserves. From pipe installations to drainage systems, you can trust us to ensure that your plumbing needs are met with the highest quality workmanship.
  </p>
</Description>

  

      </DescriptionColumn>

    </SectionContainer>
  );
}

const SectionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px;
  background-color: #111111;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center; 
  }
  @media screen and (max-width: 610px) {
    align-items: flex-start;
  }
`;

const ImageColumn = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 50%; 
`;

const Image = styled.img`
  width: 500px;
  height: 500px;
  max-width: 100%;
  border-radius: 20px;
  @media screen and (max-width: 1020px) {
    width: 300px;
    height: 300px;
  }
  @media screen and (max-width: 610px) {
    width: 150px;
    height: 150px;
    padding: 10px;
  }
`;

const DescriptionColumn = styled.div`
  flex: 1;
  padding: 10px;
  color: white;
`;

const Description = styled.div`
  h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }

  p {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 15px;
  }
`;

export default PlumbingDesc;
