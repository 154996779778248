import React from 'react'
import Navbar from '../components/Navbar'
import Headings from '../components/Headings'
import Slider from '../components/Slider'
import { plumbingSlides } from '../data/data'
import InfoBlock from '../components/InfoBlock'
import Footer from '../components/Footer'
import SpaceBlock from '../components/SpaceBlock'

const Plumbing = () => {
  return (
    <div>
    <Navbar />
    <Headings headingName="Plumbing" />
    <InfoBlock tagLine="Elevate Your Plumbing Experience with Us" simpleText="Discover unparalleled plumbing solutions crafted to perfection, just for you." />
    <Slider plumbingSlides={plumbingSlides}/>
    <SpaceBlock/>
    <Footer/>
  </div>
  )
}

export default Plumbing;