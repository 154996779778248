import React from 'react';
import styled from 'styled-components';

const ExpertDesc = () => {
  return (
    <SectionContainer>
     
      <DescriptionColumn>

      <Description>
         <h2>Quality Workmanship for Every Project</h2>
        <p>
            At Champion & Champion, we pride ourselves on delivering top-notch solutions tailored to your needs. Whether it's a residential renovation, commercial construction, or industrial project, our team ensures that every aspect is handled with care and expertise.
        </p>
        <p>
            With a dedication to integrity, innovation, and excellence, we guarantee that every project receives the attention it deserves. From conception to completion, you can trust us to ensure that your vision is realized with the highest quality workmanship.
       </p>
      </Description>
  

      </DescriptionColumn>
      <ImageColumn>
        <Image src="https://firebasestorage.googleapis.com/v0/b/champion-building.appspot.com/o/cd14b8b1-ff20-42ef-a6d0-e4930e773689.jpeg?alt=media&token=db07caab-0bf3-48e7-bbf7-e8cb36b7c421" alt="interior finish" />
      </ImageColumn>
    </SectionContainer>
  );
}

const SectionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px;
  background-color: #F5F5F5;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center; 
  }
  @media screen and (max-width: 610px) {
    align-items: flex-start;
  }
`;

const ImageColumn = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 50%; 
`;

const Image = styled.img`
  width: 500px;
  height: 500px;
  max-width: 100%;
  border-radius: 20px;
  @media screen and (max-width: 1020px) {
    width: 300px;
    height: 300px;
  }
  @media screen and (max-width: 610px) {
    width: 150px;
    height: 150px;
    padding: 10px;
  }
`;

const DescriptionColumn = styled.div`
  flex: 1;
  padding: 10px;
`;

const Description = styled.div`
  h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }

  p {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 15px;
  }
`;

export default ExpertDesc;
