import React from 'react'
import styled from 'styled-components'


const Container = () => {
  return (
    <div><ContainerBlock></ContainerBlock></div>
  )
}

export default Container;

const ContainerBlock = styled.div`
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
`;