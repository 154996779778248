import React from 'react';
import styled from 'styled-components';

const Footer = () => {
  return (
    <FooterContainer>
      <FooterWrapper>
        <Navigation>
          <NavLink href="/">Home</NavLink>
          <NavLink href="/about">About</NavLink>
          <NavLink href="/privacy-policy">Privacy Policy</NavLink>
          <NavLink href="/contact">Contact</NavLink>
        </Navigation>
        <CompanyName>Champion & Champion</CompanyName>
        <CompanyNumber href="tel:+18188095041">1 (818) 809-5041</CompanyNumber>
        <Copyright>
          &copy; 2024 Champion & Champion. All rights reserved.
        </Copyright>
      </FooterWrapper>
    </FooterContainer>
  );
}

const FooterContainer = styled.footer`
  background-color: black;
  color: #fff;
  padding: 20px 0;

  @media screen and (max-width: 500px) {
    padding: 30px 0;
  }
`;

const FooterWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Navigation = styled.nav`
  margin-bottom: 20px;
`;

const NavLink = styled.a`
  color: #fff;
  text-decoration: none;
  margin-right: 20px;
  &:hover {
    text-decoration: underline;
  }
`;

const CompanyName = styled.div`
  font-size: 1.5rem;
  margin-bottom: 10px;
`;

const CompanyNumber = styled.a`
  font-size: 1rem;
  color: #fff;
  text-decoration: none;
  margin-bottom: 10px;
  &:hover {
    text-decoration: underline;
  }
`;

const Copyright = styled.div`
  font-size: 0.9rem;
`;

export default Footer;
