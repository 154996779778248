import React, { useEffect, useState } from 'react';
import 'swiper/css';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper/core';
import { Navigation } from 'swiper/modules';
import 'swiper/swiper-bundle.css';

SwiperCore.use([Navigation]);

const Slider = ({ plumbingSlides, roofingSlides, bathroomsSlides }) => {
  const [slidesPerView, setSlidesPerView] = useState(3);

  const pathname = window.location.pathname;

let slides;

if (pathname === '/plumbing') {
  slides = plumbingSlides;
} else if (pathname === '/roofing') {
  slides = roofingSlides;
}else if (pathname === '/bathrooms') {
  slides = bathroomsSlides;
}

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setSlidesPerView(1);
      } else if (window.innerWidth <= 1200) {
        setSlidesPerView(2);
      } else {
        setSlidesPerView(3);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const StyledSwiperSlide = styled(SwiperSlide)`
    display: flex;
    justify-content: center;
    align-items: center;
  `;



  return (
    <SliderContainer>
      <Swiper
        spaceBetween={20}
        slidesPerView={slidesPerView}
        navigation
        onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper) => console.log(swiper)}
      >
        {slides && slides.length > 0 && slides.map((item, index) => (
          <StyledSwiperSlide key={item.id}>
            <img src={item.img} alt={item.alt} />
          </StyledSwiperSlide>
        ))}
      </Swiper>
    </SliderContainer>
  );
}

const SliderContainer = styled.div`
  width: 100%;
  height: auto;

  .swiper-button-prev,
  .swiper-button-next {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 100;
  }

  .swiper-button-prev {
    left: 10px;
  }

  .swiper-button-next {
    right: 10px;
  }

  .swiper-button-prev::after,
  .swiper-button-next::after {
    font-family: 'swiper-icons';
    font-size: 20px;
    font-weight: bold;
  }

  img {
    width: 500px;
    height: 500px;
    object-fit: cover; 
    margin: auto;
  }
`;

export default Slider;
