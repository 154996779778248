import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom';

const HeroBanner = () => {
  return (
    <HeroBannerContainer>
        <HeroBannerWrapper>
        <HeroImage src="https://firebasestorage.googleapis.com/v0/b/champion-building.appspot.com/o/construction.jpg?alt=media&token=905db3e5-0964-4c5a-8b59-618ff6676cf2"></HeroImage>
        <HeroCall>
            <HeroCallHeading>Build Your Dreams with Champion & Champion Construction</HeroCallHeading>
            <HeroCallTagline>Transforming Spaces, Creating Legacies</HeroCallTagline>
            <HeroCallButton style={{ textDecoration: 'none' }} as={Link} to="/contact">Contact Us</HeroCallButton>
        </HeroCall>
        </HeroBannerWrapper>
    </HeroBannerContainer>
  )
}

const HeroBannerContainer = styled.div`
  height: calc(100vh - 170px); 
  @media (max-width: 768px) {
    height: calc(100vh - 85px); 
  }

`;

const HeroBannerWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

`;

const HeroCall = styled.div`
 display: flex;
 justify-content: center;
 align-items: center;
 flex-direction: column;
 position: absolute;
 border-radius: 10px;
 background-color: rgba(255, 255, 255, 0.7);
 padding: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  @media (max-width: 768px) {
    width: 80%; 
  }
`;

const HeroCallTagline = styled.span`
padding-bottom: 20px;
`;

const HeroCallButton = styled.button`
border: none;
background: darkblue;
color: white;
cursor: pointer;
padding: 10px 20px;
font-size: 1.1rem;
border-radius: 20px;
font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
`;

const HeroCallHeading = styled.h2`
font-size: 1.5rem;
font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;

`;

const HeroImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover; 
 
`;





export default HeroBanner