import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Home from './pages/Home';
import Roofing from './pages/Roofing';
import Framing from './pages/Framing';
import Plumbing from './pages/Plumbing';
import Windows from './pages/Windows';
import Electricity from './pages/Electricity';
import Contact from './pages/Contact';
import Bathrooms from './pages/Bathrooms';



function App() {
  return (
    <Router>
      <div className="masterclass">
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/roofing" element={<Roofing/>} />
          <Route exact path="/framing" element={<Framing/>} />
          <Route exact path="/plumbing" element={<Plumbing/>} />
          <Route exact path="/windows" element={<Windows/>} />
          <Route exact path="/electricity" element={<Electricity/>} />
          <Route exact path="/bathrooms" element={<Bathrooms/>} />
          <Route exact path="/contact" element={<Contact/>} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;

