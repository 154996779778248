import React from 'react'
import styled from 'styled-components'

const SpaceBlock = () => {
  return (
    <SpacePadding></SpacePadding>
  )
}

const SpacePadding = styled.div`
padding: 10px 0;


@media screen and (max-width: 610px){
  padding: 0 0;
}
`;


export default SpaceBlock;