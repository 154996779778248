import React from 'react'
import Navbar from '../components/Navbar';
import HeroBanner from '../components/HeroBanner';
import Footer from '../components/Footer';
import MainDescSection from '../components/MainDescSection';
import RoofingDesc from '../components/RoofingDesc';
import ExpertDesc from '../components/ExpertDesc';
import PlumbingDesc from '../components/PlumbingDesc';

const Home = () => {
  return (
    <>
    <Navbar />
    <HeroBanner />
    <RoofingDesc />
    <PlumbingDesc />
    <ExpertDesc/>
    <MainDescSection/>
    <Footer/>
    </>
  )
}

export default Home