import React from 'react'
import Navbar from '../components/Navbar'
import Headings from '../components/Headings'
import ContactForm from '../components/ContactForm'
import Container from '../components/Container'
import ContactBlock from '../components/ContactBlock'
import Footer from '../components/Footer'

const Contact = () => {
  return (
    <>

        <Navbar/>
        <Headings headingName="Contact" />
        <ContactBlock/>
        <ContactForm />
        <Footer/>
    </>
  )
}

export default Contact;