import React from 'react';
import styled from "styled-components";
import { navLinks } from '../data/data.js';
import { useEffect, useState } from 'react';
import { FaPhoneAlt } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import { Link, useLocation } from 'react-router-dom'; 


function Navbar() {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const location = useLocation(); 
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


return (
  <NavbarContainer>
    <NavbarWrapper>
      <NavbarLogoAndNumber>
      <StyledLink to="/">
        <BusinessLogoContainer>
          
          <BusinessLogo alt="business-logo" src='https://firebasestorage.googleapis.com/v0/b/champion-building.appspot.com/o/champ%20logo%20v3.png?alt=media&token=f49e1b4f-a54e-4fd5-bbfb-c1432be8d836' />
        </BusinessLogoContainer>
        </StyledLink>
        {windowWidth >= 768 ? (
            <BusinessNumberContainer>
              
              <FaPhoneAlt style={{ paddingRight: '20px' }} />
              <BusinessNumber><a style={{ textDecoration: 'none', color: 'black' }} href="tel:+18188095041" title="Call us">
                  1 (818) 809-5041
                </a></BusinessNumber>
              
                <SpanishText>¡Hablamos Español!</SpanishText>
            </BusinessNumberContainer>
          ) : (
            <GiHamburgerMenu style={{ paddingRight: '20px', fontSize: '2rem' }} onClick={toggleSidebar}/>
          )}
      </NavbarLogoAndNumber>
      <SidebarContainer isOpen={isOpen}>
      <CloseButton onClick={toggleSidebar}>X</CloseButton>
      <SidebarNav>
        {navLinks.map(navlink => (
          <NavigationLink key={navlink.id}>
            <StyledLink to={`/${navlink.link}`}>
              {navlink.title.toUpperCase()}
            </StyledLink>
          </NavigationLink>
        ))}
        <FooterText>Champion & Champion &#169; 2024</FooterText>
      </SidebarNav>
    </SidebarContainer>
      {windowWidth >= 768 ? (
                 <NavigationLinks>
                 <NavigationLinksWrapper>
                 {navLinks.map((navlink) => (
              <NavigationLink key={navlink.id}>
                <StyledLink to={`/${navlink.link}`}>{navlink.title.toUpperCase()}</StyledLink>
              </NavigationLink>
            ))}
                 </NavigationLinksWrapper>
               </NavigationLinks>
          ) : (
           null
          )}

    </NavbarWrapper>
  </NavbarContainer>
);
}



const NavbarContainer = styled.div`
  height: 170px;
  @media (max-width: 768px) {
    height: 85px;
    border-bottom: 1px solid lightgray;
  }
`;

const BusinessNumber = styled.h2`
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
`;

const BusinessNumberContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-grow: 1; 
  padding-right: 20px;
`;


const NavbarLogoAndNumber = styled.div`
  display: flex;
  justify-content: space-between; /* Ensure items are spaced evenly */
  align-items: center;
  width: 100%;
`;

const NavbarWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const BusinessLogoContainer = styled.div`
  /* No specific styling needed here */
`;

const BusinessLogo = styled.img`
  width: 180px;
  height: 90px;
  cursor: pointer;
  padding: 10px;
  @media (max-width: 768px) {
    width: 120px;
  height: 60px;
  
  }
`;

const NavigationLinks = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 100%;
background-color: black;

`;

const NavigationLinksWrapper = styled.ul`
display: flex;
justify-content: space-around;
align-items: center;
width: 100%;
`

const NavigationLink = styled.li`
font-family: "Poppins", sans-serif;
font-weight: 400;
font-style: normal;
margin-left: 5px;
list-style: none;
cursor: pointer;
color: white;
&:first-child {
    margin-left: 0; 
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

const StyledActiveLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

const StyledInactiveLink = styled.span`
  color: gray; 
  cursor: not-allowed; 
`;
const SpanishText = styled.p`
 font-family: "Poppins", sans-serif;
  font-weight: 300;
  color: #333;
  font-style: italic;
  text-align: center;
  padding-left: 10px;
`;

const SidebarContainer = styled.div`
  position: fixed;
  top: 0;
  right: ${({ isOpen }) => (isOpen ? '0' : '-300px')};
  width: 300px;
  height: 100%;
  background-color: #1434A4;
  transition: right 0.3s ease-in-out;
  z-index: 10;

  a{
    font-size: 1.1rem;
    line-height: 2;
  }

  @media screen and (max-width: 560px){
    width: ${({ isOpen }) => (isOpen ? '100%' : '300px')};
  }
`;

const SidebarNav = styled.nav`
  padding: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  li:first-child {
    margin-left: 5px; 
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 2rem;
  cursor: pointer;
  background: none;
  border: none;
  color: white;
`;

const FooterText = styled.div`
  color: #fff;
  text-align: center;
  padding: 10px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`;


export default Navbar;