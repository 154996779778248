import React from 'react';
import Navbar from '../components/Navbar';
import Headings from '../components/Headings';
import Slider from '../components/Slider';
import { bathroomsSlides } from '../data/data';
import InfoBlock from '../components/InfoBlock';
import Footer from '../components/Footer';
import SpaceBlock from '../components/SpaceBlock';

const Bathrooms = () => {
  return (
    <div>
      <Navbar />
      <Headings headingName="Bathrooms" />
      <InfoBlock tagLine="Enhance Your Bathroom Experience with Us" simpleText="Discover unparalleled solutions tailored to perfection for your bathroom needs." />
      <Slider bathroomsSlides={bathroomsSlides} />
      <SpaceBlock />
      <Footer />
    </div>
  );
};

export default Bathrooms;

