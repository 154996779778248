import React from 'react'
import styled from 'styled-components';
const Headings = ({ headingName }) => {


  return (
    <HeadingContainer><h2>{headingName.toUpperCase()}</h2></HeadingContainer>
  )
}

const HeadingContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
width: 100%;

`;

export default Headings