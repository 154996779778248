import styled from "styled-components";
import React from "react";
import {
  FaEnvelope,
  FaInstagram,
  FaMapMarker,
  FaPhone,
} from "react-icons/fa";
import { useState, useRef, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


import emailjs from "@emailjs/browser";

const ContactForm = () => {

useEffect(()=>{
   console.log( process.env.REACT_APP_SERVICE_ID);
},[])

  const instaLink = "https://www.instagram.com/championandchampion/";
  const linkClick = (url) => {
    window.location.assign(url);
  };

  const blankForm = {
    name: "",
    email: "",
    message: "",
  };

  const [newFormData, setFormData] = useState(blankForm);

  const { name, email, message } = newFormData;

  const handleNameChange = (e) => {
    setFormData({
      ...newFormData,
      name: e.target.value,
    });
  };

  const handleEmailChange = (e) => {
    setFormData({
      ...newFormData,
      email: e.target.value,
    });
  };

  const handleMessageChange = (e) => {
    setFormData({
      ...newFormData,
      message: e.target.value,
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    emailjs
      .send(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        newFormData,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        function (response) {
          console.log("SUCCESS!", response.status, response.text);
          // Show success toast
          toast.success("Message successfully sent.");
        },
        function (error) {
          console.log("FAILED...", error);
        }
      );
    setFormData(blankForm);
  };

  return (
    <ContactFormStyled>
        <ToastContainer />
      <div className="contact-wrapper">
        <form
          id="contact-form"
          className="form-horizontal"
          onSubmit={handleFormSubmit}
        >
          <div className="form-group">
            <input
              type="text"
              id="name"
              placeholder="NAME"
              name="name"
              value={newFormData.name}
              onChange={handleNameChange}
              required
            />
            <input
              type="email"
              id="email"
              placeholder="EMAIL"
              name="email"
              value={newFormData.email}
              onChange={handleEmailChange}
              required
            />
            <textarea
              rows="6"
              placeholder="MESSAGE"
              name="message"
              value={newFormData.message}
              onChange={handleMessageChange}
              required
            ></textarea>
            <button
              className="btn btn-primary send-button"
              id="submit"
              type="submit"
              value="SEND"
            >
              <div className="alt-send-button">
                
                <span className="send-text">SEND</span>
              </div>
            </button>
          </div>
        </form>

        <div className="direct-contact-container">
          <ul className="contact-list">
          <li className="list-item">
              <FaPhone />
              <span className="contact-text">
                <a href="tel:+18188095041" title="Call us" style={{ textDecoration: 'none', color: 'black' }}>
                  1 (818) 809-5041
                </a>
              </span>
            </li>
            <li className="list-item">
              <FaMapMarker />
              <span className="contact-text">Los Angeles, California</span>
            </li>

            <li className="list-item">
              <FaEnvelope />
              <span className="contact-text">
                <a href="mailto:championbuilding12@gmail.com" title="Send me an email" style={{ textDecoration: 'none', color: 'black' }}>
                  championbuilding12@gmail.com
                </a>
              </span>
            </li>
          </ul>

          <div className="social-media-container">
            <ul className="social-media-list">
              <li onClick={(e) => linkClick(instaLink)}>
                <a className="contact-icon" href={instaLink}>
                  <FaInstagram />
                </a>
              </li>
            </ul>
          </div>

          <div className="copyright">Champion & Champion &copy;2024</div>
        </div>
      </div>
    </ContactFormStyled>
  );
};

const ContactFormStyled = styled.div`
  width: 100%;
  padding: 10px 0;
  display: flex;
  flex-direction: column;

  .contact-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 90%;
    

    @media screen and (max-width: 1100px) {
      flex-direction: column;
      width: 100%;
      padding-bottom: 3em;
    }
  }

  .form-horizontal {
    width: 50%;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 2em;
    @media screen and (max-width: 1100px) {
      width: 90%;
      padding: 0.5em;
    }
    @media screen and (max-width: 410px) {
      padding: 0.4em;
    }
  }
  .form-group {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .form-group input {
    width: 100%;
    background-color: transparent;
    border: none;
    border: 1px solid black;
    padding: 0.5em 0.5em;
    @media screen and (max-width: 760px) {
      font-size: 0.9rem;
    }
    @media screen and (max-width: 435px) {
      font-size: 0.7rem;
    }
  }

  textarea {
    width: 100%;
    max-width: 100%;
    max-height: 1000px  !important;
    min-height: 120px;
    min-width: 100%;
    background-color: transparent;;
    letter-spacing: 1px;
    border: 1px solid black;
    padding: 0.5em 0.5em;
    @media screen and (max-width: 760px) {
      font-size: 0.9rem;
    }

    @media screen and (max-width: 435px) {
      font-size: 0.7rem;
    }
  }

  .paper-plane {
    
  }

  .send-button {
    margin-top: 15px;
    width: 100%;
    overflow: hidden;
    transition: all 0.2s ease-in-out;
    border-radius: 0.2em;
    border: transparent;
    padding-top: 0.2em;
    background: blue;
    cursor: pointer;
  }

  .alt-send-button {
    width: 100%;
    height: 34px;
    transition: all 0.2s ease-in-out;
  }

  .send-text {
    display: block;
    margin-top: 6px;
    font: 700 12px "Poppins", sans-serif;
    letter-spacing: 2px;
    color: white;
  }


  /* Begin Right Contact Page */
  .direct-contact-container {
    max-width: 50%;
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 10px;
    @media screen and (max-width: 1100px) {
      font-size: 0.7rem;
      padding: 1em;
      width: 100%;
      align-items: center;
      span {
        max-width: 100%;
      }
    }

    @media screen and (max-width: 435px) {
      font-size: 0.3rem;
    }
  }

  /* Location, Phone, Email Section */
  .contact-list {
    list-style-type: none;
    padding: 1em 0;
    display: flex;
    flex-direction: column;
  }
  .list-item span{
    font-size: 1rem;
  }

  .list-item {
    line-height: 0.5em;
    color: black;
    display: flex;
    align-items: center;
    margin-bottom: 1em;
    @media screen and (max-width: 480px) {
      margin-bottom: 1.6em;
      line-height: 1em;
      font-size: 1rem;
      a {
        font-size: 1rem;
      }
    }
  }

  .contact-text {
    letter-spacing: 1.9px;

    text-decoration: none;
    margin-left: 1.5em;
    font-size: 1rem;
  }

  .contact-list span {
    font-size: 1rem;
    @media screen and (max-width: 480px) {
      font-size: 0.7rem;
    }
    @media screen and (max-width: 435px) {
      font-size: 0.5rem;
    }
    @media screen and (max-width: 410px) {
      font-size: 0.6rem;
    }
  }

  .contact-text a {
    text-decoration: none;
    transition-duration: 0.2s;
    font-size: 1rem;

    @media screen and (max-width: 435px) {
      font-size: 0.5rem;
    }
    @media screen and (max-width: 410px) {
      font-size: 0.6rem;
    }
  }

  .contact-text a:hover {
    color: blue;
    text-decoration: none;
  }

  /* Social Media Icons */
  .social-media-list {
    position: relative;
    font-size: 22px;
    text-align: center;
    width: 100%;
    margin: 0 auto;
    padding: 0;
    display: flex;
    justify-content: space-evenly;
  }

  .social-media-list li a {
    color: white;
  }

  .social-media-list li {
    position: relative;
    display: inline-block;
    min-height: 60px;
    width: 60px;
    justify-content: space-between;
    line-height: 60px;
    border-radius: 50%;
    color: black;
    background-color: rgb(27, 27, 27);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    @media screen and (max-width: 410px) {
      min-height: 55px;
      min-width: 55px;
    }
  }

  .social-media-list li:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 60px;
    height: 60px;
    line-height: 60px;
    border-radius: 50%;
    opacity: 0;
    box-shadow: 0 0 0 1px #fff;
    transition: all 0.2s ease-in-out;
  }

  .social-media-list li:hover {
    background-color: #fff;
  }

  .social-media-list li:hover:after {
    opacity: 1;
    transform: scale(1.12);
    transition-timing-function: cubic-bezier(0.37, 0.74, 0.15, 1.65);
  }

  .social-media-list li:hover a {
    color: #000;
  }

  .copyright {
    font: 300 14px "Montserrat", sans-serif;
    color: black;
    letter-spacing: 1px;
    text-align: center;
    margin-top: 1.2rem;
    @media screen and (max-width: 480px) {
      margin-top: 1rem;
    }
  }

  .social-media-container {
    padding: 1em;
    width: 100%;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
  }
  .alt-send-button{
  
  }
`;
export default ContactForm;