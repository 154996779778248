export const navLinks = [
    {
      id: 1,
      img: ``,
      title: "Roofing",
      link:"roofing",
      cat: "",
      alt: "",
    },
    {
        id: 2,
        img: ``,
        title: "Plumbing",
        link: "plumbing",
        cat: "",
        alt: "",
      },
  
      {
        id: 3,
        img: ``,
        title: "Bathrooms",
        link: "bathrooms",
        cat: "",
        alt: "",
      },
      {
        id: 4,
        img: ``,
        title: "Framing",
        link:"framing",
        cat: "",
        alt: "",
      },
      {
        id: 5,
        img: ``,
        title: "Windows",
        link: "windows",
        cat: "",
        alt: "",
      },
      {
        id: 6,
        img: ``,
        title: "Contact",
        link: "contact",
        cat: "",
        alt: "",
      },
  ];

  export const roofingSlides = [
    {
      id: 1,
      img: `https://firebasestorage.googleapis.com/v0/b/champion-building.appspot.com/o/82F83635-3017-4851-A5B2-1CCEBABAE8B5.jpeg?alt=media&token=3c37c3e2-1b49-48ed-b405-acf7837f6e40`,
      title: "Roofing",
      link:"roofing",
      cat: "",
      alt: "rooftop-brown",
    },
    {
        id: 2,
        img: `https://firebasestorage.googleapis.com/v0/b/champion-building.appspot.com/o/B3DF8DEE-78C6-446C-BFBC-AB50BF64139C.jpeg?alt=media&token=e14cef79-2721-4b53-b9bd-02cf6d71744e`,
        title: "Roofing",
        link:"roofing",
        cat: "",
        alt: "rooftop-working",
      },
      {
        id: 3,
        img: `https://firebasestorage.googleapis.com/v0/b/champion-building.appspot.com/o/IMG_0268.jpeg?alt=media&token=0d905203-c6e8-4038-abbc-33497f59c806`,
      title: "Roofing",
      link:"roofing",
      cat: "",
      alt: "rootop-constructing",
      },
      {
        id: 4,
        img: `https://firebasestorage.googleapis.com/v0/b/champion-building.appspot.com/o/IMG_5385.jpeg?alt=media&token=feb7bd1f-18a0-44cd-b998-5a58b1fe8c23`,
        title: "Roofing",
        link:"roofing",
        cat: "",
        alt: "rootop-constructing-top",
      },
      {
        id: 5,
        img: `https://firebasestorage.googleapis.com/v0/b/champion-building.appspot.com/o/IMG_0335.jpeg?alt=media&token=d96e8d46-5e1c-4d06-b778-c7fd407c627b`,
      title: "Roofing",
      link:"roofing",
      cat: "",
      alt: "rootop-constructing with tools",
      },
      {
        id: 6,
        img: `https://firebasestorage.googleapis.com/v0/b/champion-building.appspot.com/o/IMG_0271.jpeg?alt=media&token=51fc23ff-c013-4b6e-a6f7-4676061f8de9`,
        title: "Roofing",
        link:"roofing",
        cat: "",
        alt: "rootop-constructing with panels",
      },
      {
        id: 7,
        img: `https://firebasestorage.googleapis.com/v0/b/champion-building.appspot.com/o/IMG_0334.jpeg?alt=media&token=beeabc18-9dac-423c-b14c-a783a03a7b5a`,
        title: "Roofing",
        link:"roofing",
        cat: "",
        alt: "rootop-constructing with person in view",
      },
      {
        id: 8,
        img: `https://firebasestorage.googleapis.com/v0/b/champion-building.appspot.com/o/IMG_0346.jpeg?alt=media&token=334a112d-f164-427d-b452-375e3fca3359`,
        title: "Roofing",
        link:"roofing",
        cat: "",
        alt: "rootop-constructing with multiple people",
      },
      {
        id: 9,
        img: `https://firebasestorage.googleapis.com/v0/b/champion-building.appspot.com/o/IMG_0340.jpeg?alt=media&token=f9bf5f32-4f1b-46fa-bc74-259bcc043c0d`,
        title: "Roofing",
        link:"roofing",
        cat: "",
        alt: "rootop-with people on roof",
      },
      {
        id: 10,
        img: `https://firebasestorage.googleapis.com/v0/b/champion-building.appspot.com/o/IMG_7769.jpeg?alt=media&token=2beed7f3-5ab0-4867-b278-22ed3c1244d7`,
        title: "Roofing",
        link:"roofing",
        cat: "",
        alt: "rootop-constructing with white panels",
      },
      {
        id: 11,
        img: `https://firebasestorage.googleapis.com/v0/b/champion-building.appspot.com/o/IMG_0267.jpeg?alt=media&token=c4672a7e-983c-4b07-8ec1-a3cb32f635e6`,
        title: "Roofing",
        link: "roofing",
        cat: "",
        alt: "rooftop under construction"
      },
      {
        id: 12,
        img: `https://firebasestorage.googleapis.com/v0/b/champion-building.appspot.com/o/IMG_0281.jpeg?alt=media&token=8c190363-4457-420a-a606-0d446a1d15b6`,
        title: "Roofing",
        link: "roofing",
        cat: "",
        alt: "rooftop under construction"
      },
      {
        id: 13,
        img: `https://firebasestorage.googleapis.com/v0/b/champion-building.appspot.com/o/IMG_0272.jpeg?alt=media&token=809b9a42-7209-45ca-b5c6-8e20c0ac029d`,
        title: "Roofing",
        link: "roofing",
        cat: "",
        alt: "rooftop under construction panel"
      },


  ];

  export const plumbingSlides = [
    {
      id: 1,
      img: `https://firebasestorage.googleapis.com/v0/b/champion-building.appspot.com/o/BD25BA26-B77F-419A-B5B8-7DF9AE152CDD.jpeg?alt=media&token=e364b784-6c7f-4807-b66a-5576a3b110f8`,
      title: "Plumbing",
      link:"plumbing",
      cat: "",
      alt: "plumbing showing a tube",
    },
    {
      id: 2,
      img: `https://firebasestorage.googleapis.com/v0/b/champion-building.appspot.com/o/99F40BFF-4854-4A8A-AF24-D1DCA3DE3D8C.jpeg?alt=media&token=d13fe12d-5f18-4dae-8148-a99121cfa8c9`,
      title: "Plumbing",
      link:"plumbing",
      cat: "",
      alt: "plumbing showing a tube on ground",
      },
      {
        id: 3,
        img: `https://firebasestorage.googleapis.com/v0/b/champion-building.appspot.com/o/IMG_1422.jpeg?alt=media&token=6bc27c2b-0968-4aee-9aea-6e64ea1f410f`,
        title: "Plumbing",
        link:"plumbing",
        cat: "",
        alt: "plumbing tube on top",
      },
      {
        id: 4,
        img: `https://firebasestorage.googleapis.com/v0/b/champion-building.appspot.com/o/IMG_1421.jpeg?alt=media&token=e04dd79c-b442-4df6-85ba-2bdd84db8e98`,
        title: "Plumbing",
        link:"plumbing",
        cat: "",
        alt: "plumbing showing multiple tubes",
      },
      {
        id: 5,
        img: `https://firebasestorage.googleapis.com/v0/b/champion-building.appspot.com/o/IMG_1420.jpeg?alt=media&token=ad80bd97-4ceb-4828-b34b-804949a2fd68`,
        title: "Plumbing",
        link:"plumbing",
        cat: "",
        alt: "plumbing showing multiple tubes on ground",
      },
      {
        id: 6,
        img: `https://firebasestorage.googleapis.com/v0/b/champion-building.appspot.com/o/IMG_1423.jpeg?alt=media&token=0a5fb349-bfb7-4650-a5b7-ec723040a34a`,
        title: "Plumbing",
        link:"",
        cat: "",
        alt: "plumbing showing tube opening",
      },
      {
        id: 7,
        img: `https://firebasestorage.googleapis.com/v0/b/champion-building.appspot.com/o/IMG_1419.jpeg?alt=media&token=db65fad8-6df8-49b6-a734-08222ed0eeef`,
        title: "Plumbing",
        link:"",
        cat: "",
        alt: "plumbing showing multiple tubes on wall",
      },
      {
        id: 8,
        img: `https://firebasestorage.googleapis.com/v0/b/champion-building.appspot.com/o/IMG_1418.jpeg?alt=media&token=8d401bfc-acf2-444d-b020-be27a9861ba1`,
        title: "Plumbing",
        link:"",
        cat: "",
        alt: "plumbing showing tube on wall",
      }


  ];export const bathroomsSlides = [
    {
      id: 1,
      img: `https://firebasestorage.googleapis.com/v0/b/champion-building.appspot.com/o/image000007.jpeg?alt=media&token=33cc5b2b-dc44-46f6-b7a5-4c894d25ed07`,
      title: "Bathrooms",
      link:"bathrooms",
      cat: "",
      alt: "bathroom repair",
    },
    {
      id: 2,
      img: `https://firebasestorage.googleapis.com/v0/b/champion-building.appspot.com/o/image000005.jpeg?alt=media&token=db1b9e6f-2f11-443a-847f-e6277ad0f275`,
      title: "Bathrooms",
      link:"bathrooms",
      cat: "",
      alt: "new bathroom repair",
    },
    {
      id: 3,
      img: `https://firebasestorage.googleapis.com/v0/b/champion-building.appspot.com/o/image000000007.jpeg?alt=media&token=57d5c535-daeb-4ce7-ab1c-af395be79c6b`,
      title: "Bathrooms",
      link:"bathrooms",
      cat: "",
      alt: "new bathroom install",
    },
    {
      id: 4,
      img: `https://firebasestorage.googleapis.com/v0/b/champion-building.appspot.com/o/image000009.jpeg?alt=media&token=695c3068-8784-4115-a629-e431d862a28c`,
      title: "Bathrooms",
      link:"bathrooms",
      cat: "",
      alt: "New bathroom install repair",
    },
    {
      id: 5,
      img: `https://firebasestorage.googleapis.com/v0/b/champion-building.appspot.com/o/image000000007%203.jpeg?alt=media&token=022e9bd9-5344-4fc0-8b7b-29fa83672906`,
      title: "Bathrooms",
      link:"bathrooms",
      cat: "",
      alt: "new bathroom installation",
    },
    {
      id: 6,
      img: `https://firebasestorage.googleapis.com/v0/b/champion-building.appspot.com/o/image000000007%205.jpeg?alt=media&token=9d6906e2-8bb8-4b48-849d-1096e7fcf450`,
      title: "Bathrooms",
      link:"bathrooms",
      cat: "",
      alt: "new bathroom install",
    },
    {
      id: 7,
      img: `https://firebasestorage.googleapis.com/v0/b/champion-building.appspot.com/o/Resized_20210324_190447_43454015793226.jpeg?alt=media&token=9c7c02da-b9e0-4bf3-9659-be24f603182d`,
      title: "Bathrooms",
      link:"bathrooms",
      cat: "",
      alt: "new bathroom",
    },

  ];




