import React from 'react';
import styled from 'styled-components';

const MainDescSection = () => {
  return (
    <SectionContainer>
      <ImageColumn>
        <Image src="https://firebasestorage.googleapis.com/v0/b/champion-building.appspot.com/o/plans.jpg?alt=media&token=be398b5c-107a-49cb-a957-0d38a1ca895d" alt="work showcase" />
      </ImageColumn>
      <DescriptionColumn>
        <Description>
          <h2>Why Champion & Champion?</h2>
          <p>
            Champion & Champion is a construction company dedicated to providing high-quality services in the construction industry. With decades of experience, we specialize in various construction projects, including residential, commercial, and industrial.
          </p>
          <p>
           At Champion & Champion, we prioritize integrity, innovation, and excellence in every project we undertake.
          </p>
        </Description>
      </DescriptionColumn>
    </SectionContainer>
  );
}

const SectionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px;
  background-color: #111111;
  @media screen and (max-width: 768px) {
   flex-direction: column;
  }
  @media screen and (max-width: 610px) {
  }
`;

const ImageColumn = styled.div`
  flex: 1;
  max-width: 50%; 
`;

const Image = styled.img`
  opacity: .7;
  width: 500px;
  height: 500px;
  max-width: 100%;
  border-radius: 20px;
  @media screen and (max-width: 1020px) {
    width: 300px;
    height: 300px;
  }
  @media screen and (max-width: 610px) {
display: none;
  }

`;

const DescriptionColumn = styled.div`
  flex: 1;
  padding: 10px;
`;

const Description = styled.div`
color: white;
  h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }

  p {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 15px;
  }
`;

export default MainDescSection;
