import React from 'react';
import styled from 'styled-components';

const ContactBlock = () => {
  return (
  <InfoBlockContainer>
      <InfoBlockWrapper>
        <ContactSection>
          Ready for your next project? Contact us at Champion & Champion
        </ContactSection>
        <SpanishText>¡Hablamos Español!</SpanishText>
      </InfoBlockWrapper>
    </InfoBlockContainer>
  );
}

export default ContactBlock;

const InfoBlockContainer = styled.div`
  width: 100%;
`;

const InfoBlockWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  flex-direction: column;
`;

const ContactSection = styled.p`
  font-size: 24px;
  color: #333;
  font-weight: bold;
  text-align: center;
`;

const SpanishText = styled.p`
  font-size: 20px;
  color: #333;
  font-style: italic;
  text-align: center;
`;



const InfoBlockImage = styled.img`
  opacity: 0.5;
  max-height: 400px;
  aspect-ratio: 3 / 2;
  object-fit: contain;
`;