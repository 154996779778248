import React from 'react'
import Navbar from '../components/Navbar'
import Headings from '../components/Headings'
import Slider from '../components/Slider'
import { roofingSlides } from '../data/data'
import InfoBlock from '../components/InfoBlock'
import Footer from '../components/Footer'
import SpaceBlock from '../components/SpaceBlock'

const Roofing = () => {
  return (
    <div>
    <Navbar />
    <Headings headingName="Roofing" />
    <InfoBlock tagLine="Elevating Your Roofing Experience to New Heights" simpleText="We specialize in delivering top-notch roofing solutions tailored to your needs." />
    <Slider roofingSlides={roofingSlides}/>
    <SpaceBlock/>
    <Footer/>
  </div>
  )
}

export default Roofing