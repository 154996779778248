import React from 'react';
import styled from 'styled-components';

const InfoBlock = ({tagLine, simpleText}) => {
  return (
    <InfoBlockContainer>
    <InfoBlockWrapper>
      <InfoSection>
        {tagLine}
      </InfoSection>
      <SimpleText>
       {simpleText}
      </SimpleText>
    </InfoBlockWrapper>
  </InfoBlockContainer>
  
  );
}

export default InfoBlock


const InfoBlockContainer = styled.div`
  width: 100%;
`;

const InfoBlockWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  flex-direction: column;
`;

const InfoSection = styled.p`
  font-size: 24px;
  color: #333;
  font-weight: bold;
  text-align: center;
`;

const SimpleText = styled.p`
  font-size: 20px;
  color: #333;
  font-style: italic;
  text-align: center;
`;