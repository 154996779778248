import React from 'react';
import styled from 'styled-components';

const RoofingDesc = () => {
  return (
    <SectionContainer>
   
      <DescriptionColumn>
      <Description>
        <h2>Expert Roofing Solutions Await You</h2>
        <p>
         Experience excellence with Champion & Champion roofing services. Our team is dedicated to delivering top-notch solutions tailored to your needs. Whether it's residential, commercial, or industrial, we've got you covered.
        </p>
        <p>
        At Champion & Champion, we stand by our commitment to integrity, innovation, and excellence in every roofing project we undertake.
        </p>
      </Description>

      </DescriptionColumn>
      <ImageColumn>
        <Image src="https://firebasestorage.googleapis.com/v0/b/champion-building.appspot.com/o/IMG_0268.jpeg?alt=media&token=0d905203-c6e8-4038-abbc-33497f59c806" alt="rootop finish" />
      </ImageColumn>
    </SectionContainer>
  );
}

const SectionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px;
  background-color: #F5F5F5;
  @media screen and (max-width: 768px) {
   flex-direction: column;
  }
  @media screen and (max-width: 610px) {
   align-items: flex-start;
  }
`;

const ImageColumn = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 50%; 
`;

const Image = styled.img`
  width: 500px;
  height: 500px;
  max-width: 100%;
  border-radius: 20px;
  @media screen and (max-width: 1020px) {
    width: 300px;
    height: 300px;
  }
  @media screen and (max-width: 610px) {
    width: 150px;
    height: 150px;
    padding: 10px;
  }
`;

const DescriptionColumn = styled.div`
  flex: 1;
  padding: 10px;
`;

const Description = styled.div`
  h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }

  p {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 15px;
  }
`;

export default RoofingDesc;
